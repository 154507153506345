<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
      <v-col cols="12" lg="12">
        <BaseCard heading="Lista tipo wallets master">
            <!-- ----------------------------------------------------------------------------- -->
            <!-- TableSimpleFixHeader -->
            <!-- ----------------------------------------------------------------------------- -->
            <!-- <v-list-item-subtitle class="text-wrap">
            Use the <code>fixed-header</code> prop together with the <code>height</code> prop to fix the header to the top of the table.
            </v-list-item-subtitle> -->
            <div class="mt-0">       
            <v-data-table
                :headers="headers"
                :items="listTipowallets"
                item-key="id"
                sort-by="fecha"
                :sort-desc="true"
                :search="search"
                :items-per-page="10"
                :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right'
                }"
                :loading="loading"
                loading-text="Cargando... Un momento por favor"
            >
                <!-- This template looks for headers with formatters and executes them -->
                <!-- <template
                v-for="header in headers.filter((header) =>
                    header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
                >
                {{ header.formatter(value) }}
                </template> -->                
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>                        
                        <v-btn
                            class="mb-1"
                            fab
                            dark
                            small
                            color="green"
                            title="Agregar"
                            @click="agregar"
                        >
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn> 
                        <v-btn
                            class="mb-1"
                            fab
                            dark
                            small
                            color="success"
                            title="Refrescar"
                            @click="getTipowalletsusers"
                        >
                            <v-icon dark>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>
                </template>
                <template  v-slot:item.logo="{ item }">
                    <v-img
                        :lazy-src= item.logo
                        max-height="50"
                        max-width="50"
                        :src=item.logo
                    >
                    </v-img>
                </template>
                <template v-slot:item.actions="{ item }">
                     <v-toolbar flat>
                        <v-btn
                            class="mini-boton"
                            @click="editar(item.id)"
                            fab
                            dark
                            small
                            color="warning"
                            title="Editar"
                        >
                            <v-icon dark >mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            class="ml-2 mini-boton"
                            @click="eliminar(item.id)"
                            fab
                            dark
                            small
                            color="error"
                            title="Eliminar"
                        >
                            <v-icon dark >mdi-delete</v-icon>
                        </v-btn>
                    </v-toolbar>
                </template>
            </v-data-table>
            </div>
            <v-dialog v-model="dialogConstruccion" persistent max-width="290" >
            <v-card>
                <v-card-title class="text-h5">
                En construcción
                </v-card-title>
                <v-card-text>
                En construcción
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="setAceptar" >
                    Aceptar
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
    name: "Tipowalletsusers",

    data: () => ({
        dialogConstruccion: false,
        id_tipo_waller_user: null,
        usrToken: '',
        usrData: {},
        loading: true,
        search: '',
        listTipowallets: [],
        headers: [
            {
            text: 'Codigo',
            align: 'start',
            sortable: false,
            value: 'codigo',
            },
            { text: 'Tipo wallet', value: 'nombre' },
            { text: 'Logo', value: 'logo' },
            { text: 'Actions', value: 'actions', sortable: false, width: 125, align: 'center'},
        ],
    }),
  
    methods: {
        agregar(id){
            this.id_tipo_waller_user = id
            this.dialogConstruccion = true
        },
        editar(id){
            this.id_tipo_waller_user = id
            this.dialogConstruccion = true
        },
        eliminar(id){
            this.id_tipo_waller_user = id
            this.dialogConstruccion = true
        },
        setAceptar() {
            this.dialogConstruccion = false
        },
        getTipowalletsmaster() {
            this.loading = true
            //Obtener los datos de las respTipowalletsusers
            axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/tipowalletmaster`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken ,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                let respTipowalletsusers = response.data
                //console.log('Transacciones...', respTransaccion);
                if (!respTipowalletsusers.success){
                    console.error('Tipo wallet Error: ', respTipowalletsusers)
                }
                else {
                    this.listTipowallets = respTipowalletsusers.data.map((data) => {
                        //console.log(trans.valor, trans.valor.toLocaleString("es-VE", {style:"currency", currency:"USD"}))
                        return {
                            id: data.id,
                            codigo: data.co_wallet,
                            nombre: data.nb_tipo_wallet,
                            logo: data.tx_img_logo
                        }
                    })
                }
                
                this.loading = false
            })
            .catch(error => {
                console.error('Tipo wallets Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
            })
        }
    },
  
  async created () {
    this.usrData = await JSON.parse(localStorage.getItem("user"))
    this.usrToken = localStorage.getItem("usrToken")
    this.getTipowalletsmaster()
  }
};
</script>