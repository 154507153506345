var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"align-self-start"},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('BaseCard',{attrs:{"heading":"Lista tipo wallets master"}},[_c('div',{staticClass:"mt-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listTipowallets,"item-key":"id","sort-by":"fecha","sort-desc":true,"search":_vm.search,"items-per-page":10,"footer-props":{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  prevIcon: 'mdi-chevron-left',
                  nextIcon: 'mdi-chevron-right'
              },"loading":_vm.loading,"loading-text":"Cargando... Un momento por favor"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-1",attrs:{"fab":"","dark":"","small":"","color":"green","title":"Agregar"},on:{"click":_vm.agregar}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"mb-1",attrs:{"fab":"","dark":"","small":"","color":"success","title":"Refrescar"},on:{"click":_vm.getTipowalletsusers}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-refresh")])],1)],1)]},proxy:true},{key:"item.logo",fn:function({ item }){return [_c('v-img',{attrs:{"lazy-src":item.logo,"max-height":"50","max-width":"50","src":item.logo}})]}},{key:"item.actions",fn:function({ item }){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mini-boton",attrs:{"fab":"","dark":"","small":"","color":"warning","title":"Editar"},on:{"click":function($event){return _vm.editar(item.id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ml-2 mini-boton",attrs:{"fab":"","dark":"","small":"","color":"error","title":"Eliminar"},on:{"click":function($event){return _vm.eliminar(item.id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)],1)]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogConstruccion),callback:function ($$v) {_vm.dialogConstruccion=$$v},expression:"dialogConstruccion"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" En construcción ")]),_c('v-card-text',[_vm._v(" En construcción ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.setAceptar}},[_vm._v(" Aceptar ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }